// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-blog-article-1-js": () => import("./../../../src/pages/blog/article1.js" /* webpackChunkName: "component---src-pages-blog-article-1-js" */),
  "component---src-pages-blog-article-2-js": () => import("./../../../src/pages/blog/article2.js" /* webpackChunkName: "component---src-pages-blog-article-2-js" */),
  "component---src-pages-blog-article-3-js": () => import("./../../../src/pages/blog/article3.js" /* webpackChunkName: "component---src-pages-blog-article-3-js" */),
  "component---src-pages-blog-article-4-js": () => import("./../../../src/pages/blog/article4.js" /* webpackChunkName: "component---src-pages-blog-article-4-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-customers-js": () => import("./../../../src/pages/customers.js" /* webpackChunkName: "component---src-pages-customers-js" */),
  "component---src-pages-customers-signbox-js": () => import("./../../../src/pages/customers/signbox.js" /* webpackChunkName: "component---src-pages-customers-signbox-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-services-dedicated-resources-js": () => import("./../../../src/pages/services/dedicated-resources.js" /* webpackChunkName: "component---src-pages-services-dedicated-resources-js" */),
  "component---src-pages-services-devops-support-js": () => import("./../../../src/pages/services/devops-support.js" /* webpackChunkName: "component---src-pages-services-devops-support-js" */),
  "component---src-pages-services-full-app-development-js": () => import("./../../../src/pages/services/full-app-development.js" /* webpackChunkName: "component---src-pages-services-full-app-development-js" */)
}

